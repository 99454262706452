
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as about_45usWaPtO2K3LNfyEFyzrMOuS4PCucWQbcZ_45f0xmsY_oUHcMeta } from "/opt/render/project/src/apps/website/client/pages/about-us.vue?macro=true";
import { default as _91id_93nuhFBMHBYsaRz2nWJlMSHhvWg3f4hevx1DSveZxV8u0Meta } from "/opt/render/project/src/apps/website/client/pages/blog/[id].vue?macro=true";
import { default as loging_JEiGUDLZ_vrbbUN7oO4fOzFaoBr5PGjchhK_me0wQMeta } from "/opt/render/project/src/apps/website/client/pages/auth/login.vue?macro=true";
import { default as indexgQ00ZXcM8ayNKTxu6x911cpQqyjArtHTb4o9aEgYRIMMeta } from "/opt/render/project/src/apps/website/client/pages/blog/index.vue?macro=true";
import { default as onboardingkYocuhHFerkO7wcMUMeiuVrDxKS1VxAqUKoLJyBdzWYMeta } from "/opt/render/project/src/apps/website/client/pages/onboarding.vue?macro=true";
import { default as indexSZH2aRAQkAkorjPC_45ewYH3r6QZau9syYLlO6BJAIlesMeta } from "/opt/render/project/src/apps/website/client/pages/index/index.vue?macro=true";
import { default as indexC56_MFaCLsVMMBwHl7J1Se9KosBWkvSFRIh5KxeNaDoMeta } from "/opt/render/project/src/apps/website/client/pages/events/index.vue?macro=true";
import { default as index8zwheK5Duh4vfEHeNiv0PlcOE7WLFfOwcQ4jlRzeprYMeta } from "/opt/render/project/src/apps/website/client/pages/academy/index.vue?macro=true";
import { default as callbackKEdPpeeJ6RzCKV0EtsDSMl8QM2jAzBV9Y0WEvDTkAdQMeta } from "/opt/render/project/src/packages/authentication/client/pages/auth/callback.vue?macro=true";
import { default as indexTz4SaI_JvNzd0eynDqLvu_45C_45c7Z7woVlyN4bje9hkYkMeta } from "/opt/render/project/src/apps/website/client/pages/catalog/index/search/index.vue?macro=true";
import { default as _91slug_93XGSRF5Rwry2nesgXo8h5pPmjKnhrUUkKdirvWNutyLAMeta } from "/opt/render/project/src/apps/website/client/pages/catalog/index/categories/[slug].vue?macro=true";
import { default as indexpFuhsyqz9KItHeD3rLSvuIUPpISqN_I8QGOtCmHsoiAMeta } from "/opt/render/project/src/apps/website/client/pages/catalog/index/top-trainers/index.vue?macro=true";
import { default as index36H_45uPI8RTJydrJIst_45TtrHPM_45VWAFQWDgAuDVOiNKQMeta } from "/opt/render/project/src/apps/website/client/pages/catalog/index.vue?macro=true";
import { default as indexYgbzKbswXHaUgKDhIDjW9qZeBz_MEi3itqnkfYYTwAcMeta } from "/opt/render/project/src/apps/website/client/pages/contact/index.vue?macro=true";
import { default as verifyeiOF89LpLNmzzRrPQDS2YMP_KFf7ez3DeS7wK0C0_pUMeta } from "/opt/render/project/src/packages/authentication/client/pages/auth/v1/verify.vue?macro=true";
import { default as become_45trainerrTM8Jgt4QQIu6w7g2W_45sVBQcSpssX_UvRPsvO_dMuuAMeta } from "/opt/render/project/src/apps/website/client/pages/become-trainer.vue?macro=true";
import { default as programWhLbBXt1bkhbKNcEcZduuLw9WpA_yiY2LtzQivDqPcQMeta } from "/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/program.vue?macro=true";
import { default as og_45imageJj1nXBgOdomPX_4550T_R2I07CeQBDaI3FeJsB4R4aCFoMeta } from "/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/og-image.vue?macro=true";
import { default as _91certificateId_93U0prnt9G7tv_XPEvow3TVJsj7dzF9F8dYtv_DhLJch4Meta } from "/opt/render/project/src/apps/website/client/pages/documents/index/certificates/[certificateId].vue?macro=true";
import { default as email_45coverLIWLXZOC6L9WbKc8NbynIbzMQmeeNuCZJeqaAG_XKKkMeta } from "/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/email-cover.vue?macro=true";
import { default as coverZzdy79sPTUKvyPR_45Hd0X9vt6yJiCZtxUBJFL1yvIwO8Meta } from "/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/sessions/[sessionId]/cover.vue?macro=true";
import { default as og_45imageYUYDn75mq5tivAr6xrBTGDS2DSejaC9AndyHE2xMNUsMeta } from "/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/sessions/[sessionId]/og-image.vue?macro=true";
import { default as signaturezmynUM0xtJg14WcSguDqhmrov7GIKSbIRe_js_mh6KoMeta } from "/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/sessions/[sessionId]/signature.vue?macro=true";
import { default as indexW_45zZH_F09uXAL_45vo8LDn_45XddxAQ2AoUkHBB7CqFyk0EMeta } from "/opt/render/project/src/apps/website/client/pages/documents/index.vue?macro=true";
import { default as indexcyGqwtyqr_45486hTE_45LLPQTYBC4pNvVM0SMFhuyWeRNQMeta } from "/opt/render/project/src/apps/website/client/pages/enterprise/index.vue?macro=true";
import { default as getting_45financedZUeYfI6avnNOOLZXsP8DAYZsE1mtB_FU6voDdV_QbnwMeta } from "/opt/render/project/src/apps/website/client/pages/getting-financed.vue?macro=true";
import { default as _91_46_46_46slug_93b_45QQBwyzQ_456uBBjYuSe4o6GG0dvpxOyC8JrH1zZ5wIsMeta } from "/opt/render/project/src/apps/website/client/pages/content/[...slug].vue?macro=true";
import { default as indexRAYQY6_tAL8bQ03SWzMH5y6uTzM_45tZ0NeZBnWZPI6ucMeta } from "/opt/render/project/src/apps/website/client/pages/methodology/index.vue?macro=true";
import { default as restricted_45access0UjjcIEwq4gG14Zb_457ai7h2jULnDjPNo7EcS_452kFMHIMeta } from "/opt/render/project/src/apps/website/client/pages/restricted-access.vue?macro=true";
import { default as index_45ZpdhYWwqxL0Svk0UokJxYgxmX3xs31TzBUdAh5hi60Meta } from "/opt/render/project/src/apps/website/client/pages/events/[slug]/index.vue?macro=true";
import { default as index2zLqX_GhUywzGVScoYYNKS3EPFVZjTOZO8f04gaei8UMeta } from "/opt/render/project/src/apps/website/client/pages/courses/[slug]/index.vue?macro=true";
import { default as indexNEIhMZB5bgcexKTNwHWjVlFKgu8h_45vwfrY4V4x_JlWMMeta } from "/opt/render/project/src/apps/website/client/pages/trainers/[slug]/index.vue?macro=true";
import { default as indexT_q2ojjWPv0KIopCcmcd40Ho4qqjeJ_45Tz83qtJW4TkAMeta } from "/opt/render/project/src/apps/website/client/pages/partnerships/malt/index.vue?macro=true";
import { default as _91certificateId_93oHMa8W_4kXon7JfRyMTZovWY8LRoqCAFsAL1TQl6LHoMeta } from "/opt/render/project/src/apps/website/client/pages/certificates/[certificateId].vue?macro=true";
import { default as indexdMQnV8GSEVpqZmrU2eKRd1sg7m7CsLxSuuURta28jyYMeta } from "/opt/render/project/src/apps/website/client/pages/partnerships/act-for-impact/index.vue?macro=true";
import { default as satisfaction_45form_45callbackXGW3X2YBxWKxX3QouR8aStC75AaCy65HFH66ZbuPvUMMeta } from "/opt/render/project/src/apps/website/client/pages/account/satisfaction-form-callback.vue?macro=true";
import { default as erroravyPNrB6i8Do_45UPupzSmKP1gvsY_q3YMCSacplfmg0gMeta } from "/opt/render/project/src/apps/website/client/pages/courses/[slug]/sessions/[sessionId]/enrol/index/error.vue?macro=true";
import { default as indexc96J4rSWwDcv8FWiznxWQouD53GvAyeCzTDYvu0YUUcMeta } from "/opt/render/project/src/apps/website/client/pages/courses/[slug]/sessions/[sessionId]/enrol/index/index.vue?macro=true";
import { default as successly1dqWWoQoYyD5W_453prrM3XDYMmOAH1iP73H4iE9Lc8Meta } from "/opt/render/project/src/apps/website/client/pages/courses/[slug]/sessions/[sessionId]/enrol/index/success.vue?macro=true";
import { default as funding_45instructionsnum9z9ZcYc6IIgAc7SCGN_45y6X_45DUphttjdK0ykZTSsMMeta } from "/opt/render/project/src/apps/website/client/pages/courses/[slug]/sessions/[sessionId]/enrol/index/funding-instructions.vue?macro=true";
import { default as indexD7dlAtHr9mT6UjiL6KkDpIn_mBN6mWtmZivQqIYgc8cMeta } from "/opt/render/project/src/apps/website/client/pages/courses/[slug]/sessions/[sessionId]/enrol/index.vue?macro=true";
import { default as component_45stubARPQEb5Ycgtj_VHxEk0MRYGwSkmafuZI8byhvIGdmjoMeta } from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_encoding@0.1.13_eslint@9.13.0_rtakcjwmdzj44zxctrlmeolqpe/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubARPQEb5Ycgtj_VHxEk0MRYGwSkmafuZI8byhvIGdmjo } from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_encoding@0.1.13_eslint@9.13.0_rtakcjwmdzj44zxctrlmeolqpe/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about-us",
    path: "/about-us",
    meta: about_45usWaPtO2K3LNfyEFyzrMOuS4PCucWQbcZ_45f0xmsY_oUHcMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/about-us.vue")
  },
  {
    name: "blog-id",
    path: "/blog/:id()",
    meta: _91id_93nuhFBMHBYsaRz2nWJlMSHhvWg3f4hevx1DSveZxV8u0Meta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/blog/[id].vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loging_JEiGUDLZ_vrbbUN7oO4fOzFaoBr5PGjchhK_me0wQMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/auth/login.vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexgQ00ZXcM8ayNKTxu6x911cpQqyjArtHTb4o9aEgYRIMMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/blog/index.vue")
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: onboardingkYocuhHFerkO7wcMUMeiuVrDxKS1VxAqUKoLJyBdzWYMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/onboarding.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/render/project/src/apps/website/client/pages/index/index.vue")
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/opt/render/project/src/apps/website/client/pages/events/index.vue")
  },
  {
    name: "academy",
    path: "/academy",
    component: () => import("/opt/render/project/src/apps/website/client/pages/academy/index.vue")
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    meta: callbackKEdPpeeJ6RzCKV0EtsDSMl8QM2jAzBV9Y0WEvDTkAdQMeta || {},
    component: () => import("/opt/render/project/src/packages/authentication/client/pages/auth/callback.vue")
  },
  {
    name: "catalog",
    path: "/catalog",
    component: () => import("/opt/render/project/src/apps/website/client/pages/catalog/index.vue"),
    children: [
  {
    name: "catalog-index-search",
    path: "search",
    meta: indexTz4SaI_JvNzd0eynDqLvu_45C_45c7Z7woVlyN4bje9hkYkMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/catalog/index/search/index.vue")
  },
  {
    name: "catalog-index-categories-slug",
    path: "categories/:slug()",
    meta: _91slug_93XGSRF5Rwry2nesgXo8h5pPmjKnhrUUkKdirvWNutyLAMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/catalog/index/categories/[slug].vue")
  },
  {
    name: "catalog-index-top-trainers",
    path: "top-trainers",
    component: () => import("/opt/render/project/src/apps/website/client/pages/catalog/index/top-trainers/index.vue")
  }
]
  },
  {
    name: "contact",
    path: "/contact",
    meta: indexYgbzKbswXHaUgKDhIDjW9qZeBz_MEi3itqnkfYYTwAcMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/contact/index.vue")
  },
  {
    name: "auth-v1-verify",
    path: "/auth/v1/verify",
    meta: verifyeiOF89LpLNmzzRrPQDS2YMP_KFf7ez3DeS7wK0C0_pUMeta || {},
    component: () => import("/opt/render/project/src/packages/authentication/client/pages/auth/v1/verify.vue")
  },
  {
    name: "become-trainer",
    path: "/become-trainer",
    component: () => import("/opt/render/project/src/apps/website/client/pages/become-trainer.vue")
  },
  {
    name: "documents",
    path: "/documents",
    meta: indexW_45zZH_F09uXAL_45vo8LDn_45XddxAQ2AoUkHBB7CqFyk0EMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/documents/index.vue"),
    children: [
  {
    name: "documents-index-courses-courseId-program",
    path: "courses/:courseId()/program",
    meta: programWhLbBXt1bkhbKNcEcZduuLw9WpA_yiY2LtzQivDqPcQMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/program.vue")
  },
  {
    name: "documents-index-courses-courseId-og-image",
    path: "courses/:courseId()/og-image",
    component: () => import("/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/og-image.vue")
  },
  {
    name: "documents-index-certificates-certificateId",
    path: "certificates/:certificateId()",
    component: () => import("/opt/render/project/src/apps/website/client/pages/documents/index/certificates/[certificateId].vue")
  },
  {
    name: "documents-index-courses-courseId-email-cover",
    path: "courses/:courseId()/email-cover",
    component: () => import("/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/email-cover.vue")
  },
  {
    name: "documents-index-courses-courseId-sessions-sessionId-cover",
    path: "courses/:courseId()/sessions/:sessionId()/cover",
    component: () => import("/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/sessions/[sessionId]/cover.vue")
  },
  {
    name: "documents-index-courses-courseId-sessions-sessionId-og-image",
    path: "courses/:courseId()/sessions/:sessionId()/og-image",
    component: () => import("/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/sessions/[sessionId]/og-image.vue")
  },
  {
    name: "documents-index-courses-courseId-sessions-sessionId-signature",
    path: "courses/:courseId()/sessions/:sessionId()/signature",
    meta: signaturezmynUM0xtJg14WcSguDqhmrov7GIKSbIRe_js_mh6KoMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/sessions/[sessionId]/signature.vue")
  }
]
  },
  {
    name: "enterprise",
    path: "/enterprise",
    component: () => import("/opt/render/project/src/apps/website/client/pages/enterprise/index.vue")
  },
  {
    name: "getting-financed",
    path: "/getting-financed",
    component: () => import("/opt/render/project/src/apps/website/client/pages/getting-financed.vue")
  },
  {
    name: "content-slug",
    path: "/content/:slug(.*)*",
    meta: _91_46_46_46slug_93b_45QQBwyzQ_456uBBjYuSe4o6GG0dvpxOyC8JrH1zZ5wIsMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/content/[...slug].vue")
  },
  {
    name: "methodology",
    path: "/methodology",
    component: () => import("/opt/render/project/src/apps/website/client/pages/methodology/index.vue")
  },
  {
    name: "restricted-access",
    path: "/restricted-access",
    meta: restricted_45access0UjjcIEwq4gG14Zb_457ai7h2jULnDjPNo7EcS_452kFMHIMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/restricted-access.vue")
  },
  {
    name: "events-slug",
    path: "/events/:slug()",
    component: () => import("/opt/render/project/src/apps/website/client/pages/events/[slug]/index.vue")
  },
  {
    name: "courses-slug",
    path: "/courses/:slug()",
    component: () => import("/opt/render/project/src/apps/website/client/pages/courses/[slug]/index.vue")
  },
  {
    name: "trainers-slug",
    path: "/trainers/:slug()",
    component: () => import("/opt/render/project/src/apps/website/client/pages/trainers/[slug]/index.vue")
  },
  {
    name: "partnerships-malt",
    path: "/partnerships/malt",
    meta: indexT_q2ojjWPv0KIopCcmcd40Ho4qqjeJ_45Tz83qtJW4TkAMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/partnerships/malt/index.vue")
  },
  {
    name: "certificates-certificateId",
    path: "/certificates/:certificateId()",
    meta: _91certificateId_93oHMa8W_4kXon7JfRyMTZovWY8LRoqCAFsAL1TQl6LHoMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/certificates/[certificateId].vue")
  },
  {
    name: "partnerships-act-for-impact",
    path: "/partnerships/act-for-impact",
    meta: indexdMQnV8GSEVpqZmrU2eKRd1sg7m7CsLxSuuURta28jyYMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/partnerships/act-for-impact/index.vue")
  },
  {
    name: "account-satisfaction-form-callback",
    path: "/account/satisfaction-form-callback",
    meta: satisfaction_45form_45callbackXGW3X2YBxWKxX3QouR8aStC75AaCy65HFH66ZbuPvUMMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/account/satisfaction-form-callback.vue")
  },
  {
    name: indexD7dlAtHr9mT6UjiL6KkDpIn_mBN6mWtmZivQqIYgc8cMeta?.name,
    path: "/courses/:slug()/sessions/:sessionId()/enrol",
    meta: indexD7dlAtHr9mT6UjiL6KkDpIn_mBN6mWtmZivQqIYgc8cMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/courses/[slug]/sessions/[sessionId]/enrol/index.vue"),
    children: [
  {
    name: "courses-slug-sessions-sessionId-enrol-index-error",
    path: "error",
    meta: erroravyPNrB6i8Do_45UPupzSmKP1gvsY_q3YMCSacplfmg0gMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/courses/[slug]/sessions/[sessionId]/enrol/index/error.vue")
  },
  {
    name: "courses-slug-sessions-sessionId-enrol-index",
    path: "",
    meta: indexc96J4rSWwDcv8FWiznxWQouD53GvAyeCzTDYvu0YUUcMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/courses/[slug]/sessions/[sessionId]/enrol/index/index.vue")
  },
  {
    name: "courses-slug-sessions-sessionId-enrol-index-success",
    path: "success",
    component: () => import("/opt/render/project/src/apps/website/client/pages/courses/[slug]/sessions/[sessionId]/enrol/index/success.vue")
  },
  {
    name: "courses-slug-sessions-sessionId-enrol-index-funding-instructions",
    path: "funding-instructions",
    component: () => import("/opt/render/project/src/apps/website/client/pages/courses/[slug]/sessions/[sessionId]/enrol/index/funding-instructions.vue")
  }
]
  },
  {
    name: component_45stubARPQEb5Ycgtj_VHxEk0MRYGwSkmafuZI8byhvIGdmjoMeta?.name,
    path: "/search",
    component: component_45stubARPQEb5Ycgtj_VHxEk0MRYGwSkmafuZI8byhvIGdmjo
  },
  {
    name: component_45stubARPQEb5Ycgtj_VHxEk0MRYGwSkmafuZI8byhvIGdmjoMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubARPQEb5Ycgtj_VHxEk0MRYGwSkmafuZI8byhvIGdmjo
  }
]