import revive_payload_client_h4TwhKo_b4bgGZS09Ra0BFupoR6ckn14vAjM0aKKIqM from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_encoding@0.1.13_eslint@9.13.0_rtakcjwmdzj44zxctrlmeolqpe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_1ikHn_I1MWlO8TRp_vXBNswnfI4G_f9w9vM5mOig5u0 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_encoding@0.1.13_eslint@9.13.0_rtakcjwmdzj44zxctrlmeolqpe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_u3ZetPjyT_hQCGeYD0_roLaTELJedSc6epkfketE7tI from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_encoding@0.1.13_eslint@9.13.0_rtakcjwmdzj44zxctrlmeolqpe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_qaX77Ocjx57NKuqomq_AjUbBoZ5_0A26SLhDhmxmjBs from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+supabase@1.4.6/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import _0_siteConfig_uL6TPMe0sNSVq2ADYvX4YFrIBZc16pxexrMnmK5Y73A from "/opt/render/project/src/node_modules/.pnpm/nuxt-site-config@3.1.6_magicast@0.3.5_vue@3.5.13_typescript@5.6.3_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import feature_flags_plugin__F8dkiuMqoTT15JVAyqUHHFXvSi8BzKMxJWQJYleMc0 from "/opt/render/project/src/packages/core/client/plugins/feature-flags.plugin.ts";
import i18n_core_plugin_xwGTXBtUAAnvXA_UN8O5rAf7MrBRm7rvv48H0hWnb1k from "/opt/render/project/src/packages/core/client/plugins/i18n-core.plugin.ts";
import payload_client__72e9Zp6rUv2ijnvlI9IQoJMqYcuM9Vi0Pw2Ivqnotw from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_encoding@0.1.13_eslint@9.13.0_rtakcjwmdzj44zxctrlmeolqpe/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_GlMHFUHV6i3dR4zlw2M8bjIKo2mgFRUraLq0G8qdM3w from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_encoding@0.1.13_eslint@9.13.0_rtakcjwmdzj44zxctrlmeolqpe/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_5OdkAkLYERWyxRZMEZCX4DlpgRNGAU7SaOji9qV0kTg from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_encoding@0.1.13_eslint@9.13.0_rtakcjwmdzj44zxctrlmeolqpe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_AuefUQ4XtykEeFf8j8a54Z0n30hpD0jnM1ZySA5VCN4 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_encoding@0.1.13_eslint@9.13.0_rtakcjwmdzj44zxctrlmeolqpe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/opt/render/project/src/apps/website/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_WUcdDb2X93n11up5DSRR8YzaABF_2cZn7COIzV6r0io from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_encoding@0.1.13_eslint@9.13.0_rtakcjwmdzj44zxctrlmeolqpe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import motion_5jOzYJ26zaS1_5iVGZZH07bKN03pbxbhdHPL_N4qE4Y from "/opt/render/project/src/node_modules/.pnpm/@vueuse+motion@2.1.0_magicast@0.3.5_vue@3.5.13_typescript@5.6.3_/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import plugin_ALbOSdPRj6XRdZk2y8tBRAi3rkili5BHh7tn5x_C_Aw from "/opt/render/project/src/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_vite@6.2.5_@types+node@22.13.9_jiti@2.4.2_sass@1.69.4_stylus_73yby47onwsbkeq7tnms7agpoe/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import i18n_plugin_HLCxbxBuB1RwsaSwBlaCtpJ35mssAWQHfqt_C3h5fNk from "/opt/render/project/src/packages/authentication/client/plugins/i18n.plugin.ts";
import custom_directives_plugin_G1mUNYRJZW_yuiT9dIX0IOKFVKOjDBv0nmmss31cn1I from "/opt/render/project/src/packages/ui-library/client/plugins/custom-directives.plugin.ts";
import form_plugin_client_b_EZclRbr3486iUEDV8BM2seR4zVz3iQ0CPB4YsnMQY from "/opt/render/project/src/packages/ui-library/client/plugins/form.plugin.client.ts";
import i18n_plugin_4kpfLV_JWVzP59JPSOvOJ17K_tB5SgT_km2NujEIJTQ from "/opt/render/project/src/packages/ui-library/client/plugins/i18n.plugin.ts";
import event_plugin_client_yr5rcg5m9sRZOSX2aEoUrwEJQ7KGm28UwgeJaBD9QxY from "/opt/render/project/src/packages/core/client/plugins/event.plugin.client.ts";
import hydration_plugin_4u7ge4Fnrrxu3_K2T3vh_ltzvab8ZjV3C5RJejtWPqU from "/opt/render/project/src/packages/core/client/plugins/hydration.plugin.ts";
import monitoring_plugin_client_7cVasBIQ9IJ58_I4ufFAMdzkAE5Uavls07sTKwx18_w from "/opt/render/project/src/packages/core/client/plugins/monitoring.plugin.client.ts";
import _1_host_plugin__eZsOZgFiAVSyCMWqu72lUkD0qtICMfRobwp01PwgZE from "/opt/render/project/src/apps/website/client/plugins/1.host.plugin.ts";
import analytics_plugin_L797kMzQMmgLtY6Qm0U9cDulpsjykFEO5zFJKiiJ_bY from "/opt/render/project/src/apps/website/client/plugins/analytics.plugin.ts";
import i18n_plugin_x6u0lQNygNbE0o7QMiM_xZNWNGrIGp_8wPUPoJX5pjM from "/opt/render/project/src/apps/website/client/plugins/i18n.plugin.ts";
import seo_plugin_shheF_epHFaekSwjgPBwE197mYKEF6t2hClgmEPHn_4 from "/opt/render/project/src/apps/website/client/plugins/seo.plugin.ts";
export default [
  revive_payload_client_h4TwhKo_b4bgGZS09Ra0BFupoR6ckn14vAjM0aKKIqM,
  unhead_1ikHn_I1MWlO8TRp_vXBNswnfI4G_f9w9vM5mOig5u0,
  router_u3ZetPjyT_hQCGeYD0_roLaTELJedSc6epkfketE7tI,
  supabase_client_qaX77Ocjx57NKuqomq_AjUbBoZ5_0A26SLhDhmxmjBs,
  _0_siteConfig_uL6TPMe0sNSVq2ADYvX4YFrIBZc16pxexrMnmK5Y73A,
  feature_flags_plugin__F8dkiuMqoTT15JVAyqUHHFXvSi8BzKMxJWQJYleMc0,
  i18n_core_plugin_xwGTXBtUAAnvXA_UN8O5rAf7MrBRm7rvv48H0hWnb1k,
  payload_client__72e9Zp6rUv2ijnvlI9IQoJMqYcuM9Vi0Pw2Ivqnotw,
  navigation_repaint_client_GlMHFUHV6i3dR4zlw2M8bjIKo2mgFRUraLq0G8qdM3w,
  check_outdated_build_client_5OdkAkLYERWyxRZMEZCX4DlpgRNGAU7SaOji9qV0kTg,
  chunk_reload_client_AuefUQ4XtykEeFf8j8a54Z0n30hpD0jnM1ZySA5VCN4,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_WUcdDb2X93n11up5DSRR8YzaABF_2cZn7COIzV6r0io,
  motion_5jOzYJ26zaS1_5iVGZZH07bKN03pbxbhdHPL_N4qE4Y,
  plugin_ALbOSdPRj6XRdZk2y8tBRAi3rkili5BHh7tn5x_C_Aw,
  i18n_plugin_HLCxbxBuB1RwsaSwBlaCtpJ35mssAWQHfqt_C3h5fNk,
  custom_directives_plugin_G1mUNYRJZW_yuiT9dIX0IOKFVKOjDBv0nmmss31cn1I,
  form_plugin_client_b_EZclRbr3486iUEDV8BM2seR4zVz3iQ0CPB4YsnMQY,
  i18n_plugin_4kpfLV_JWVzP59JPSOvOJ17K_tB5SgT_km2NujEIJTQ,
  event_plugin_client_yr5rcg5m9sRZOSX2aEoUrwEJQ7KGm28UwgeJaBD9QxY,
  hydration_plugin_4u7ge4Fnrrxu3_K2T3vh_ltzvab8ZjV3C5RJejtWPqU,
  monitoring_plugin_client_7cVasBIQ9IJ58_I4ufFAMdzkAE5Uavls07sTKwx18_w,
  _1_host_plugin__eZsOZgFiAVSyCMWqu72lUkD0qtICMfRobwp01PwgZE,
  analytics_plugin_L797kMzQMmgLtY6Qm0U9cDulpsjykFEO5zFJKiiJ_bY,
  i18n_plugin_x6u0lQNygNbE0o7QMiM_xZNWNGrIGp_8wPUPoJX5pjM,
  seo_plugin_shheF_epHFaekSwjgPBwE197mYKEF6t2hClgmEPHn_4
]